<template>
    <form>
        <div class="modal-card" style="width: auto; height: 100%">
            <header class="modal-card-head">
                <p class="modal-card-title">{{title}}</p>
                <b-select v-if="isEdit" v-model="edit_type" icon="earth" size="is-small">
                    <option value="nutrition">nutrition</option>
                    <option value="en_us">en_us</option>
                    <option value="he_is">he_is</option>
                </b-select>
            </header>
            <section class="modal-card-body">
                <b-field label="name:" horizontal>
                    <b-input v-model="nutrition.name"/>
                </b-field>
                <b-field label="glucose index:" horizontal>
                    <b-numberinput v-model="nutrition.glucose_index" :min="0" />
                </b-field>
                <b-field label="carbs:" horizontal>
                    <b-numberinput v-model="nutrition.carbs" :min="0" />
                </b-field>
                <b-field label="portion:" horizontal>
                    <b-numberinput v-model="nutrition.portion" :min="0" />
                </b-field>
                <b-field label="glucose overload:" horizontal>
                    <b-numberinput v-model="nutrition.glucose_overload" :min="0" />
                </b-field>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-rounded is-danger" type="button" @click="closeModal">Close</button>
                <button v-if="isLoading" class="button is-rounded is-success" disabled>Save</button>
                <button v-else class="button is-rounded is-success" @click="createNutrition">Save</button>
            </footer>
            <b-loading :is-full-page=false :can-cancel=false :active.sync="isLoading"></b-loading>
        </div>
    </form>
</template>

<script>
    import RespInfo from "../../utils/respinfo";
    import {mapGetters} from 'vuex'

    export default {
        name: "AddNutritionForm",
        props: {
            'pCloseModal': {},
            isEdit: Boolean,
            role: Number,
            id: Number,
        },
        data() {
            return {
                name: '',
                hebrewName: '',
                title: '',
                translations: [],
                creation: false,
                edit_type: 'nutrition',
                nutrition: {
                    name: '',
                    glucose_index: 0.0,
                    carbs: null,
                    portion: null,
                    glucose_overload: 0.0,
                }
            }
        },
        created() {
            let textrole = 'Yes';
            if (this.role == 2) textrole = 'Maybe';
            if (this.isEdit) {
                this.title = "Edit '" + textrole + "' Nutrition";
                this.nutrition = Object.assign({},this.getNutrition(this.id));
                this.getTranslations(this.id)
            } else {
                this.title = "Add '" + textrole + "' Nutrition";
                this.nutrition.role = this.role;
            }
        },
        computed: {
            ...mapGetters(['getNutrition','getNutritionStatus']),
            isLoading() {
                return this.getNutritionStatus === 'loading'
            }
        },
        methods: {
            closeModal: function () {
                this.pCloseModal();
            },
            async createNutrition() {
                let resp;
                if (this.isEdit) {
                    if (this.edit_type === 'nutrition') {
                        resp = await this.$store.dispatch('NUTRITION_INSTRUCTIONS_UPDATE', this.nutrition)
                    } else {
                        if (this.creation) {
                            resp = await this.$store.dispatch('TRANSLATION_CREATE', {
                                'entity': 'nutrition_item',
                                'entity_id': this.id,
                                'locale': this.edit_type,
                                'data': this.nutrition
                            });
                        } else {
                            resp = await this.$store.dispatch('TRANSLATION_EDIT', {
                                'entity': 'nutrition_item',
                                'entity_id': this.id,
                                'locale': this.edit_type,
                                'data': this.nutrition
                            });
                        }
                    }
                } else {
                    if (!this.isLoading) {
                        window.console.log(this.isLoading);
                        resp = await this.$store.dispatch('NUTRITION_INSTRUCTIONS_CREATE', this.nutrition);
                    }
                }
                if (resp !== undefined) {
                 //   alert(JSON.stringify(resp));
                    this.$buefy.toast.open(RespInfo(resp));

                    if (resp.status !== 200 && resp.status !== 201) {
                        this.$log.error(resp);
                    } else {
                        this.pCloseModal();
                    }
                } else {alert('undefined response')}
            },
            async getTranslations(id) {
                const responce = await this.$store.dispatch('TRANSLATIONS_GET', {
                    'entity': 'nutrition_item',
                    'entity_id': id
                });
                this.translations = responce.data
            }
        },
        watch: {
            edit_type: function () {
                let textrole = 'Yes';
                if (this.role == 2) textrole = 'Maybe';
                switch (this.edit_type) {
                    case 'nutrition':
                        this.title = this.title = "Edit '" + textrole + "' Nutrition";
                        this.nutrition = this.getNutrition(this.id);
                        break;
                    case 'en_us':
                        this.title = "Edit " + this.edit_type + " locale";
                        this.nutrition = Object.assign({}, this.translations['en_us'].data);
                        break;
                    default:
                        this.title = "Edit " + this.edit_type + " locale";
                        if (this.translations.hasOwnProperty(this.edit_type)) {
                            this.creation = false;
                            this.nutrition = Object.assign({}, this.translations[this.edit_type].data);
                        } else {
                            this.nutrition = Object.assign({},this.getNutrition(this.id));
                            this.creation = true;
                        }
                }
            }
        }
    }
</script>

<style scoped>

</style>
