<template>
    <form action="">
        <div class="modal-card" style="width: auto; height: 100%">
            <header class="modal-card-head">
                <p class="modal-card-title"> {{item.name}}</p>
            </header>
            <section class="modal-card-body" style="text-align: left">
                <b>Active ingredient: </b>{{item.active_ingredient}}
                <hr/>
                <b>Way of consumption: </b>{{item.way_of_consumption}}
                <hr/>
                <b>Type: </b>{{item.type}}
                <hr/>
                <b>Side essects: </b>{{item.side_effect}}
                <hr/>
                <b>Comments: </b>{{item.comments}}
                <hr/>
                <b>Is Subsuduzed: </b>{{item.is_subsidized}}
            </section>
            <footer class="modal-card-foot">
                <button class="button" type="button" @click="closeModal">Close</button>
            </footer>
        </div>
    </form>
</template>
<script>
    export default {
        name: "ViewMedicineItem",
        props: {
            item:{},
            pCloseModal:{},
        },
        methods: {
            closeModal(){
                this.pCloseModal()
            }
        }

    }
</script>

<style scoped>

</style>