<template>
    <section style="height: 100vh;">
        <b-field horizontal label="filter:" class="filter">
            <b-input v-model="filter"/>
        </b-field>
        <div class="tile is-ancestor tilecontainer" v-drag-and-drop:options="dndoptions">
            <div class="tile is-parent nopaddings" >
                <div class="tile is-child notification nopaddings">
                    <div class="content paddings" >
                        <div class="title nopaddings nutrition-list-header">
                            Yes
                        </div>
                        <div class="content nutrition-list-body">
                            <NutritionInstructions :role=1 :filter="filter" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="tile is-parent nopaddings">
                <div class="tile is-child notification nopaddings">
                    <div class="content paddings">
                        <div class="title nopaddings nutrition-list-header">Maybe</div>
                        <div class="content nutrition-list-body">
                            <NutritionInstructions :role=2 :filter="filter" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {mapGetters} from 'vuex'
    import NutritionInstructions from "../components/NutritiionInstructions/NutritionInstructions";

    export default {
        name: "NutritionInstructionsPage",
        components: {NutritionInstructions},
        data(){
            return {
                filter: '',
                dndoptions: {},
            }
        },
        created() {
            this.refresh();
            this.dndoptions = {
                dropzoneSelector:  '.dropzone',
                draggableSelector: '.draggable',
                handlerSelector:   '.draghandler',
                showDropzoneAreas: false,
                onDragend: function (event) {
                    if (event.droptarget != null) {
                        let id = event.items[0].attributes['id'].value;
                        let fromrole = event.items[0].attributes['role'].value;
                        let torole = event.droptarget.attributes['role'].value;
                        if (fromrole !== torole) {
                            let nutrition = Object.assign({}, this.getNutrition(id));
                            nutrition.role = Number(torole);
                            this.updateNutrition(nutrition);
                        }
                    }
                }
            };
        },
        computed:{
            ...mapGetters(['getNutrition'])
        },
        methods: {
            async refresh() {
                this.filter ='loading';
                await this.$store.dispatch('NUTRITION_INSTRUCTIONS_GET');
                this.filter = '';
            },
            async updateNutrition(nutrition){
                await this.$store.dispatch('NUTRITION_INSTRUCTIONS_UPDATE', nutrition);
                const filter = this.filter;
                this.filter ='moving';
                this.filter = filter;
            }
        }
    }
</script>

<style scoped>
    .filter {
        min-height: 45px;
        padding: 5px;
        padding-right: 15px;
        margin: 0;
    }

    .tilecontainer {
        padding: 0px;
        margin: 0;
        padding-top: 45px;
        margin-top: -45px;
        height: 100%;
    }

    .nopaddings {
        padding: 0;
        margin: 0;
    }

    .paddings {
        margin: 0;
        padding: 10px;
        height: 100%;
    }

    .nutrition-list-header {
        height: 50px;
        margin: 0;
    }

    .nutrition-list-body {
        height:100%;
        padding: 0px;
        margin: 0px;
        padding-top: 50px;
        margin-top: -50px;
    }
</style>