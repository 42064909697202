<template>
    <section>
        <b-table
                :data="data"
                :paginated="true"
                :per-page="perPage"
                :current-page.sync="currentPage"
                :pagination-simple="isPaginationSimple"
                :pagination-position="paginationPosition"
                :default-sort-direction="defaultSortDirection"
                :sort-icon="sortIcon"
                :sort-icon-size="sortIconSize"
                default-sort="unread"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
                v-on:click="clickRow" >
            <template slot-scope="props" >
                <b-table-column field="name" label="Name" sortable searchable>
                    {{ getChatsUser(props.row.id, getAuthId).name }}
                </b-table-column>
                <b-table-column field="created_at" label="Time in the Program" sortable>
                    {{ timeInTheProgram(getChatsUser(props.row.id, getAuthId).time_in_program) }}
                </b-table-column>

                <b-table-column v-if="props.row.hasOwnProperty('unread')" field="unread" label="Unread" sortable>
                    <div v-if="props.row.unread>0" class="unread">
                        {{props.row.unread}}
                    </div>
                    <div v-else class="read">
                        {{props.row.unread}}
                    </div>
                </b-table-column>
                <b-table-column v-else field="chats" label="Unread" sortable>
                    no chat
                </b-table-column>
            </template>
        </b-table>
    </section>
</template>
<script>
    import {mapGetters} from 'vuex';
    //import RespInfo from "../utils/respinfo";
    import moment from 'moment'

    export default {
        name: 'UsersTable',
        data() {
            return {
                data: [],
                currentPage: 1,
                perPage: 50,
                isPaginationSimple: true,
                paginationPosition: 'top',
                defaultSortDirection: 'desc',
                sortIcon: 'arrow-up',
                sortIconSize: 'is-small',
                isCompModalActive: false,
            }
        },
        created() {
            this.refresh();
            //window.addEventListener('resize', this.resizeTable);
            //this.resizeTable()
        },
        methods: {
            async refresh() {
                if (this.getChatsExpired) {
                    await this.$store.dispatch('CHATS_GET');
                }
                this.data = this.getChats;
            },
            timeInTheProgram(date) {
                return moment(date).toNow();
            },
            resizeTable() {
                this.perPage = Math.floor((window.innerHeight - 250) / 42);
            },
            formatDate(date) {
                return moment(date).format("LL-HH:mm:ss")
            },
            clickRow(payload){
                const room_id = payload.id;
                const client_id = this.getChatsUser(room_id, this.getAuthId).id;
                const client_name = this.getChatsUser(room_id, this.getAuthId).name;
                this.$emit('selectchat', {room: room_id, client: client_id, name: client_name});
            },
            clear_chat(){
                this.$emit('selectchat', {room: 0, client: "", name: ''});
            }
        },
        computed: {
            ...mapGetters([
                "getChats",
                "getChatsExpired",
                "getAuthId",
                "getChatsUser"
            ])
        }
    }
</script>

<style scoped>
    .unread {
        display: block;
        padding: 2px 5px 2px 5px;
        background-color: red;
        text-align: center;
        border-radius: 10px;
        color: white;
    }

    .read {
        display: block;
        padding: 2px 5px 2px 5px;
        background-color: green;
        text-align: center;
        border-radius: 10px;
        color: white;
    }
</style>