<template>
    <form action="" @submit.prevent="createSupplementsItem">
        <div class="modal-card" style="width: auto; height: 100%">
            <header class="modal-card-head">
                <p class="modal-card-title">{{title}}</p>
            </header>
            <section class="modal-card-body" style="text-align: left; min-width: 500px">
                <b-field label="Name">
                    <b-input type="Name"
                             v-model="editable.name"
                             maxlength="150"
                             pattern=".{3,}"
                             required>
                    </b-input>
                </b-field>
                <b-field label="Active ingredient">
                    <b-input v-model="editable.active_ingredient"
                             maxlength="150"
                             pattern=".{3,}"
                             required>
                    </b-input>
                </b-field>
                <b-field label="Way of consumption">
                    <b-input type="textarea"
                             v-model="editable.way_of_consumption"
                             maxlength="500"
                             pattern=".{3,}"
                             required>
                    </b-input>
                </b-field>
                <b-field label="Comments">
                    <b-input type="textarea"
                             v-model="editable.comments"
                             maxlength="500">
                    </b-input>
                </b-field>
            </section>
            <footer class="modal-card-foot">
                    <button class="button" type="button" @click="closeModal">Close</button>
                <button class="button is-primary">Save</button>
            </footer>
        </div>
    </form>
</template>
<script>
    import RespInfo from "../../utils/respinfo";

    export default {
        name: "AddSupplementsItem",
        props: {
            item:{},
            pCloseModal:{},
            isEdit: Boolean
        },
        data(){
          return {
              title: "Add Supplements Item",
              editable:{}
          }
        },
        created() {
            if (this.isEdit) {
                this.title = "Edit Supplements Item";
                this.supplements_id = this.item.id;
                //this.old_edit_type = this.edit_type;
            }
            if (typeof this.item == 'object') {
                this.editable = JSON.parse(JSON.stringify(this.item)) // hack for deep copy\
                //delete this.question['category']
                //this.questions['question'] = JSON.parse(JSON.stringify(this.fdata))
            }
            //this.getTranslations();
        },
        methods: {
            closeModal(){
                this.pCloseModal()
            },
            async createSupplementsItem() {
                let resp;
                if (this.isEdit) {
                    /*this.questions[this.old_edit_type] = JSON.parse(JSON.stringify(this.question));
                    for (let [key, value] of Object.entries(this.questions)) {
                        if (key === 'question') {
                            resp = await this.$store.dispatch(QUESTIONS_EDIT, value);
                        } else {
                            let dataValue = JSON.parse(JSON.stringify(this.questions['question']))
                            dataValue.title = value.title;
                            dataValue.category_id = value.category_id;
                            for (let ansKey in dataValue.answers) {
                                if (dataValue.answers.hasOwnProperty(ansKey)) {
                                    dataValue.answers[ansKey].title = value.answers[ansKey].title;
                                }
                            }
                            if (this.translations.hasOwnProperty(key)) {
                                resp = await this.$store.dispatch(TRANSLATION_EDIT, {
                                    'entity': 'question',
                                    'entity_id': value.id,
                                    'locale': key,
                                    'data': dataValue
                                });
                            } else {
                                resp = await this.$store.dispatch(TRANSLATION_CREATE, {
                                    'entity': 'question',
                                    'entity_id': value.id,
                                    'locale': key,
                                    'data': dataValue
                                });
                            }
                        }
                    }*/
                    resp = await this.$store.dispatch("SUPPLEMENTS_ITEMS_EDIT", this.editable);
                } else {
                    resp = await this.$store.dispatch("SUPPLEMENTS_ITEMS_CREATE", this.editable);
                }
                this.$buefy.toast.open(RespInfo(resp));
                if (resp.status !== 200 && resp.status !== 201) {
                    this.$log.error(resp);
                } else {
                    this.pCloseModal();
                }
            }
        }
    }
</script>

<style scoped>

</style>