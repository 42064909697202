<template>
    <form>
        <div class="modal-card" style="width: auto; height: 100%">
            <header class="modal-card-head">
                <p class="modal-card-title">{{title}}</p>
                <b-select v-if="isEdit" v-model="edit_type" icon="earth" size="is-small">
                    <option value="nutrition">nutrition</option>
                    <option value="en_us">en_us</option>
                    <option value="he_is">he_is</option>
                </b-select>
            </header>
            <section class="modal-card-body">
                <div>
                    <input type="file" ref="doc" @change="readFile()"/>
                    <div>[
                        <span v-for="(name, key) in names" v-bind:key="key">
                            <span v-if="key>0">|</span>
                            <b>{{name}}</b>
                        </span>]
                    </div>

                </div>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-rounded is-danger" type="button" @click="closeModal">Close</button>
                <button v-if="isLoading" class="button is-rounded is-success" disabled>Save</button>
                <button v-else class="button is-rounded is-success" @click="createNutrition">Save</button>
            </footer>
            <b-loading :is-full-page=false :can-cancel=false :active.sync="isLoading"></b-loading>
        </div>
    </form>
</template>

<script>
    import RespInfo from "../../utils/respinfo";
    import {mapGetters} from "vuex";

    export default {
        name: "AddNutritionListForm",
        props: {
            'pCloseModal': {},
            isEdit: Boolean,
            role: Number,
            id: Number,
        },
        data() {
            return {
                name: '',
                hebrewName: '',
                title: '',
                translations: [],
                creation: false,
                edit_type: 'nutrition',
                nutritions: {},
                names: [],
            }
        },
        created() {
            let textrole = 'Yes';
            if (this.role == 2) textrole = 'Maybe';
            this.title = "Add '" + textrole + "' Nutrition List";
            this.nutritions.role = this.role;
        },
        methods: {
            closeModal: function () {
                this.pCloseModal();
            },
            async createNutrition() {
                let resp;
                if (this.names.length > 0) {
                    if (!this.isLoading) {
                        window.console.log(this.isLoading);
                        resp = await this.$store.dispatch('NUTRITION_INSTRUCTIONS_LIST_CREATE', this.nutritions);
                        if (resp !== undefined) {
                            this.$buefy.toast.open(RespInfo(resp));
                            if (resp.status !== 200 && resp.status !== 201) {
                                this.$log.error(resp);
                            } else {
                                this.pCloseModal();
                            }
                        } else {
                            alert('undefined response')
                        }
                    }
                }
            },
            readFile() {
                this.file = this.$refs.doc.files[0];
                const reader = new FileReader();
                if (this.file.name.includes(".txt")) {
                    reader.onload = (res) => {
                        let names = res.target.result.split('|')
                        this.names = names
                        this.nutritions.names = names
                    };
                    reader.onerror = (err) => this.$log.error(err);
                    reader.readAsText(this.file);
                } else {
                    this.content = "check the console for file output";
                    reader.onload = (res) => {
                        this.$log.error(res.target.result);
                    };
                    reader.onerror = (err) => this.$log.error(err);
                    reader.readAsText(this.file);
                }
            }
        },
        computed: {
            ...mapGetters(['getNutrition', 'getNutritionStatus']),
            isLoading() {
                return this.getNutritionStatus === 'loading'
            }
        },
    }
</script>

<style scoped>

</style>