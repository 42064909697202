<template>
    <section>
        <ChatTemplatesCategories></ChatTemplatesCategories>
    </section>
</template>

<script>
    import ChatTemplatesCategories from '../components/ChatTemplates/ChatTemplatesCategories'
    export default {
        name: "ChatTemplatesPage",
        components: {
            ChatTemplatesCategories,
        },
    }
</script>

<style scoped>

</style>