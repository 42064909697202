<template>
    <form @submit.prevent="createCategory">
        <div class="modal-card" style="width: auto; height: 100%">
            <header class="modal-card-head">
                <p class="modal-card-title">{{title}}</p>
                <b-select v-if="isEdit" v-model="edit_type" icon="earth" size="is-small">
                    <option value="category">category</option>
                    <option value="en_us">en_us</option>
                    <option value="he_is">he_is</option>
                </b-select>
            </header>
            <section class="modal-card-body">
                <b-field label="name:" horizontal>
                    <b-input v-model="category.name"/>
                </b-field>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-rounded is-danger" type="button" @click="closeModal">Close</button>
                <button v-if="isLoading" class="button is-rounded is-success" disabled>Save</button>
                <button v-else class="button is-rounded is-success">Save</button>
            </footer>
            <b-loading :is-full-page=false :can-cancel=false :active.sync="isLoading"></b-loading>
        </div>
    </form>
</template>

<script>

    import {mapGetters} from 'vuex';
    import RespInfo from "../../utils/respinfo";

    export default {
        name: "AddChatTemplatesCategoryForm",
        props: {
            'pCloseModal': {},
            'pRefresh': {},
            isEdit: Boolean,
            fdata: {},
        },
        data(){
            return {
                title: 'Add a template category',
                edit_type: 'category',
                category: {},
                categories: {},
            }
        },
        created(){
            if (this.isEdit) {
                this.title = "Edit Chat Templates Category";
                this.category = JSON.parse(JSON.stringify(this.fdata));
                delete this.category.templates;
                this.getTranslations(this.category.id);
            } else {
                this.title = "Add Chat Templates Category";
                this.category = {
                    name: '',
                }
            }
            this.old_edit_type = (" " + this.edit_type).slice(1);
            this.categories={};
        },
        computed: {
            ...mapGetters(['getChatTemplateStatus']),
            isLoading(){
                return this.getChatTemplateStatus === 'loading'
            }
        },
        methods: {
            closeModal() {
                this.pCloseModal();
            },
            async createCategory() {
                let resp;

                if (this.isEdit) {
                    this.categories[this.old_edit_type] = JSON.parse(JSON.stringify(this.category));
                    for (let [key, value] of Object.entries(this.categories)) {
                        if (key === 'category') {
                            delete value.templates;
                            resp = await this.$store.dispatch('CHAT_TEMPLATES_CATEGORY_UPDATE', value);
                        } else {
                            if (this.translations.hasOwnProperty(key)) {
                                resp = await this.$store.dispatch('TRANSLATION_EDIT', {
                                    'entity': 'response_template_category',
                                    'entity_id': value.id,
                                    'locale': key,
                                    'data': value
                                });
                            } else {
                                resp = await this.$store.dispatch('TRANSLATION_CREATE', {
                                    'entity': 'response_template_category',
                                    'entity_id': value.id,
                                    'locale': key,
                                    'data': value
                                });
                            }
                        }
                    }
                } else {
                    if (!this.isLoading) {
                        resp = await this.$store.dispatch('CHAT_TEMPLATES_CATEGORY_ADD', this.category)
                    }
                }
                if (resp !== undefined) {
                    this.$buefy.toast.open(RespInfo(resp));
                    if (resp.status !== 200 && resp.status !== 201) {
                        this.$log.error(resp);
                    } else {
                        this.pCloseModal();
                        this.pRefresh();
                    }
                } else {alert('undefined response')}
            },
            async getTranslations(id) {
                const responce = await this.$store.dispatch('TRANSLATIONS_GET', {
                    'entity': 'response_template_category',
                    'entity_id': id
                });
                this.translations = responce.data
            }
        },
        watch: {
            edit_type: function () {
                this.title = "Edit " + this.edit_type + " locale";
                this.categories[this.old_edit_type] = JSON.parse(JSON.stringify(this.category));
                switch(this.edit_type) {
                    case 'category':
                        if (this.categories.hasOwnProperty('category')) {
                            this.category = JSON.parse(JSON.stringify(this.categories['category']));
                        }
                        break;
                    default:
                        if (this.categories.hasOwnProperty(this.edit_type)) {
                            this.category = JSON.parse(JSON.stringify(this.categories[this.edit_type]));
                        } else {
                            if (this.translations.hasOwnProperty(this.edit_type)) {
                                this.category = JSON.parse(JSON.stringify(this.translations[this.edit_type].data));
                            } else {
                                this.category = JSON.parse(JSON.stringify(this.fdata));
                            }
                        }
                }
                this.old_edit_type = (" " + this.edit_type).slice(1)
            }
        }
    }
</script>

<style scoped>

</style>