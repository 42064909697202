<template>
    <section>
        <div class="modal-card" style="width: auto; height: 100%">
            <header class="modal-card-head">
                <p class="modal-card-title">{{title}}</p>
                <b-select v-if="isButtonsNotValid" v-model="edit_type" icon="earth" size="is-small" disabled="">
                    <option value="template">template</option>
                    <option value="en_us">en_us</option>
                    <option value="he_is">he_is</option>
                    <option value="he_is_female">he_is(female)</option>
                </b-select>
                <b-select v-else-if="isEdit" v-model="edit_type" icon="earth" size="is-small" >
                    <option value="template">template</option>
                    <option value="en_us">en_us</option>
                    <option value="he_is">he_is</option>
                    <option value="he_is_female">he_is(female)</option>
                </b-select>
            </header>
            <section class="modal-card-body">
                <b-field label="name:" horizontal>
                    <b-input v-model="template.name"/>
                </b-field>
                <b-field v-for="(variant, key) in template.variants" :key="variantsComponentKey+'_'+key">
                    <div class="variant content ">
                        <div v-html="variant"></div>
                    </div>
                    <button class="button is-rounded is-success is-small" @click="activateAMDialog(key)">
                        <b-icon icon="microphone"></b-icon>
                    </button>
                    <b-button @click="editVariant(key)" class="button is-rounded is-success is-small">
                        <b-icon icon="pencil"></b-icon>
                    </b-button>
                    <b-button @click="variants.splice(key,1)" class="button is-rounded is-danger is-small">
                        <b-icon class="is-small is-danger" icon="close-circle"></b-icon>
                    </b-button>
                </b-field>
<!--                Todo: vuex store state should be via mutation handlers -->
                <b-button @click="variants.push(baseTemplate)" class="button is-rounded is-success">add template</b-button>
                <hr />

                <edit-button-form v-for="(button, key) in buttons" :key="buttonsComponentKey+'_'+key"
                                  :data="button"
                                  :index="key"
                                  @save="updateButton"
                                  @remove="deleteButton"
                />
                <b-button @click="addButton" class="button is-rounded is-success">add button</b-button>
            </section>
            <footer class="modal-card-foot">
                <b-button class="button is-rounded is-danger" type="button" @click="closeModal">Close</b-button>
                <b-button v-if="isLoading||isButtonsNotValid" class="button is-rounded is-success" disabled>Save</b-button>
                <b-button v-else class="button is-rounded is-success" @click="createTemplate">Save</b-button>
            </footer>
            <b-loading :is-full-page=false :can-cancel=false :active.sync="isLoading"></b-loading>
        </div>
        <rich-text-editor v-if="isRTEActive"
                          :is-active="isRTEActive"
                          v-on:close="isRTEActive=false"
                          v-on:save="saveVariant"
                          :data="RTEData"
                          ref="rte"
        />
        <audio-message v-if="isAudioMessageActive"
                       :is-active="isAudioMessageActive"
                       :is-template="true"
                       v-on:save="saveVariant"
                       v-on:close="isAudioMessageActive=false"
                       :button-text="buttonText"
        />
    </section>
</template>

<script>

    import {mapGetters} from 'vuex';
    import RespInfo from "../../utils/respinfo";
    import RichTextEditor from "./RichTextEditor"
    import EditButtonForm from "./EditButtonForm";
    import {uuid} from 'vue-uuid'
    import AudioMessage from "@/components/ClientPage/AudioMessage";

    export default {
        name: "AddChatTemplateForm",
        components: {
            AudioMessage,
            EditButtonForm,
            RichTextEditor,
        },
        props: {
            category_id: Number,
            'pCloseModal': {},
            'pRefresh': {},
            isEdit: Boolean,
            fdata: {},
        },
        data() {
            return {
                buttonText: 'Save',
                title: 'Add a template',
                edit_type: 'template',
                old_edit_type: '',
                creation: false,
                template: {},
                templates: {},
                isRTEActive: false,
                isAudioMessageActive: false,
                isSelectTemplateActive: false,
                RTEData: null,
                RTEKey: -1,
                buttonKey: -1,
                baseTemplate: '<p dir="rtl" style="text-align: right;"></p>',
                baseButton: {caption:'Button', template_id:-1 },
                selectedTemplate: {},
                buttonsComponentKey: 0,
                variantsComponentKey: 0,
                isButtonsNotValid: true,
            }
        },
        created() {
            if (this.isEdit) {
                this.title = "Edit Chat Template";
                this.template = Object.assign({}, this.fdata);
                this.getTranslations(this.template.id);
            } else {
                this.title = "Add Chat Template";
                this.template = {
                    name: '',
                    variants: [],
                    buttons: []
                }
            }
            this.old_edit_type = (" " + this.edit_type).slice(1);
            this.refreshButtons()
            //this.templates = {}
        },
        computed: {
            ...mapGetters(['getChatTemplateStatus']),
            isLoading() {
                return this.getChatTemplateStatus === 'loading'
            },
            variants: {
                get(){
                    return this.template.variants
                },
                set(payload){
                    this.template.variants = payload
                }
            },
            buttons: {
                get(){
                    return this.template.buttons
                },
                set(payload){
                    this.template.buttons = payload
                }
            }
        },
        methods: {
            addButton() {
                let buttons = JSON.parse(JSON.stringify(this.buttons))
                this.buttons = []
                let newButton = JSON.parse(JSON.stringify(this.baseButton));
                newButton['id'] = uuid.v1()
                buttons.push(newButton)
                this.buttons = buttons
                this.refreshButtons()
            },
            refreshButtons(){
                this.buttonsComponentKey += 1;
                this.isButtonsNotValid = this.validateButtons()
            },
            refreshVariants(){
                this.variantsComponentKey += 1;
            },
            updateButton(payload){
                this.buttons[payload.index]= JSON.parse(JSON.stringify(payload.button))
                this.isButtonsNotValid = this.validateButtons()
            },
            deleteButton(payload){
                let buttons = JSON.parse(JSON.stringify(this.buttons))
                this.buttons = []
                buttons.splice(payload.index, 1)
                this.buttons = buttons
                this.refreshButtons()
            },
            closeModal() {
                this.pCloseModal();
            },
            async createTemplate() {
                let resp;
                if (this.isEdit) {
                    this.templates[this.old_edit_type] = JSON.parse(JSON.stringify(this.template));
                    for (let [key, value] of Object.entries(this.templates)) {
                        if (key === 'template') {
                            resp = await this.$store.dispatch('CHAT_TEMPLATES_UPDATE', {
                                template: value,
                                category_id: this.category_id
                            })
                        } else {
                            if (this.translations.hasOwnProperty(key)) {
                                resp = await this.$store.dispatch('TRANSLATION_EDIT', {
                                    'entity': 'response_template',
                                    'entity_id': value.id,
                                    'locale': key,
                                    'data': value
                                });
                            } else {
                                resp = await this.$store.dispatch('TRANSLATION_CREATE', {
                                    'entity': 'response_template',
                                    'entity_id': value.id,
                                    'locale': key,
                                    'data': value
                                });
                            }
                        }
                    }
                } else {
                    if (!this.isLoading) {
                        resp = await this.$store.dispatch('CHAT_TEMPLATES_ADD', {
                            template: this.template,
                            category_id: this.category_id
                        })
                    }
                }
                if (resp !== undefined) {
                    this.$buefy.toast.open(RespInfo(resp));
                    if (resp.status !== 200 && resp.status !== 201) {
                        this.$log.error(resp);
                    } else {
                        this.pCloseModal();
                        this.pRefresh();
                    }
                } else {
                    alert('undefined response')
                }
            },
            async getTranslations(id) {
                const responce = await this.$store.dispatch('TRANSLATIONS_GET', {
                    'entity': 'response_template',
                    'entity_id': id
                });
                this.translations = responce.data
            },
            activateAMDialog(key) {
                this.RTEKey = key;
                this.isAudioMessageActive = true;
            },
            editVariant(key) {
                this.RTEKey = key;
                this.RTEData = JSON.parse(JSON.stringify(this.variants[key]))
                this.isRTEActive = true;
//                this.RichTextEditor.$emit('editorChange', this.RTEData)
                //this.$refs['rte'].$emit('editorChange', this.RTEData);
                this.refreshVariants();
            },
            selectTemplate(key){
                this.buttonKey = key
                this.isSelectTemplateActive = true;
                this.selectedTemplate = JSON.parse(JSON.stringify(this.template))
            },
            saveVariant(payload) {
                this.template.variants[this.RTEKey] = JSON.parse(JSON.stringify(payload))
                this.refreshVariants();
            },
            setSelectedTemplate(payload) {
                this.template.buttons[payload.index].template_id = payload.template_id;
            },
            validateButtons() {
                for (let button of this.buttons) {
                    if (button.template_id < 0) return true
                }
                return false
            },
        },
        watch: {
            edit_type: function () {
                this.title = "Edit " + this.edit_type + " locale";
                this.templates[this.old_edit_type] = JSON.parse(JSON.stringify(this.template));
                this.template.variants = [];
                this.template.buttons = [];
                switch (this.edit_type) {
                    case 'template':
                        if (this.templates.hasOwnProperty('template')) {
                            this.template = JSON.parse(JSON.stringify(this.templates['template']));
                        }
                        break;
                    default:
                        if (this.templates.hasOwnProperty(this.edit_type)) {
                            this.template = JSON.parse(JSON.stringify(this.templates[this.edit_type]));
                        } else {
                            if (this.translations.hasOwnProperty(this.edit_type)) {
                                this.template = JSON.parse(JSON.stringify(this.translations[this.edit_type].data));
                            } else {
                                this.template = JSON.parse(JSON.stringify(this.fdata));
                            }
                        }
                }
                this.old_edit_type = (" " + this.edit_type).slice(1)
            }
        }
    }
</script>

<style scoped>
    .variant {
        width: 100%;
        border: 1px solid lightgray;
        border-radius: 5px;
        padding: 5px;
    }
</style>