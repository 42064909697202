<template>
    <section>
        <b-modal :active="isActive" v-on:close="$emit('close')">
            <div class="container">
                <input id="my-file" type="file" name="my-file" style="display: none;" onchange=""/>
                <editor apiKey="ojvhjg3lin41e0ua4oj1n4gwcsh6t537z13etql77do8c43y" :init="options" v-model="editorData"/>
                <div class="buttons-block">
                    <b-button type="is-rounded is-danger" @click="cancel">Cancel</b-button>&nbsp;
                    <b-button type="is-rounded is-success" @click="isPreviewActive=true">Preview</b-button>&nbsp;
                    <b-button type="is-rounded is-success" @click="save">Save</b-button>&nbsp;
                </div>
            </div>
        </b-modal>
        <b-modal v-if="isPreviewActive" :active="isPreviewActive" v-on:close="isPreviewActive=false">
            <div class="container">
                <vue-phone-preview :content="editorData"/>
            </div>
        </b-modal>
    </section>
</template>
<script>
    import Editor from '@tinymce/tinymce-vue'
    import Vuex from "../../store";

    import VuePhonePreview from '../ClientPage/ChatMessagePreview'

    function removeTags(str) {
        if ((str===null) || (str===''))
            return false;
        else
            str = str.toString();
        return str.replace( /(<([^>]+)>)/ig, '').replace(/\s/g, '').replace(/&nbsp;/gi,'');
    }

    const placeholders = [
        "name",
        "first_name",
        "full_name",
        "last_glucose_level"
    ]

    let rte = {
        name: "RichTextEditor",
        props: {
            isActive: Boolean,
            data: String,
        },
        components: {
            editor: Editor,
            VuePhonePreview
        },
        mounted() {
            this.editorData = this.data;
        },
        data() {
            return {
                editorData: '<p dir="rtl" style="text-align: right;"></p>',
                isPreviewActive: false,
                options: {
                    height: 500,
                    menubar: true,
                    noneditable_regexp: /\{(.*?)\}/g,
                    plugins: [
                        'advlist autolink lists link image media charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount directionality'
                    ],
                    toolbar:
                        ['undo redo | formatselect | bold italic backcolor forecolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | ltr rtl | image | media | removeformat | help', placeholders.join(" ")],
                    setup: function (editor) {
                        placeholders.forEach(placeholder => {
                            editor.ui.registry.addButton(placeholder, {
                                text: '{' + placeholder + '}',
                                toolbar: placeholder,
                                onAction: function () {
                                    editor.insertContent(' {' + placeholder + '} ');
                                }
                            })
                        });
                    },
                    file_picker_callback: function (callback, value, meta) {
                        var input;
                        if (meta.filetype == 'image') {
                            input = document.getElementById('my-file');
                            input.setAttribute('accept', 'image/*');
                            input.click();
                            input.onchange = function () {
                                var file = input.files[0];
                                var reader = new FileReader();
                                reader.onload = async function (e) {
                                    let image = await rte.methods.sendFile({data: e.target.result, type: 'image/jpeg'});
                                    callback(image, {
                                        alt: file.name
                                    });
                                };
                                reader.readAsArrayBuffer(file);
                            };
                        }
                        if (meta.filetype == 'media') {
                            input = document.getElementById('my-file');
                            input.setAttribute('accept', 'audio/*');
                            input.click();
                            input.onchange = function () {
                                var file = input.files[0];
                                var reader = new FileReader();
                                reader.onload = async function (e) {
                                    let audio = await rte.methods.sendFile({data: e.target.result, type: 'audio/mpeg'});
                                    callback(audio, {
                                        alt: file.name
                                    });
                                };
                                reader.readAsArrayBuffer(file);
                            }
                        }
                    },
                },
                placeholders: [
                    "name",
                    "first_name",
                    "full_name",
                    "last_glucose_level"
                ]
            }
        },
        methods: {
            save() {
                const stripedText = removeTags(this.editorData);

                if (this.editorData.includes("<audio") || stripedText.length > 0) {
                    this.$emit('save', this.editorData);
                    this.$emit('close');
                } else {
                    this.$log.info('empty message');
                }
            },
            cancel() {
                this.$emit('close');
            },
            addText(text) {
                this.editorData += '{' + text + '}';
            },
            async sendFile({data, type}) {
                let resp = await Vuex.dispatch('FILE_UPLOAD', {data: data, type: type});
                return resp.data;
            },
        }
    }

    export default rte;
</script>

<style scoped>
    .container {
        padding: 10px;
        background: none;
        overflow: visible;
    }

    .buttons-block {
        padding: 5px 0 0 0;
        text-align: right;
    }

    #d1_ifr {
        min-height: 750px;
    }

    .placeholders {
        text-align: left;
    }

    .placeholder {
        display: inline-block;
        padding: 1px;
    }
</style>