<template>
    <b-modal :active="isActive">
        <form action="" @submit.prevent="saveTemplate">
            <div class="modal-card" style="width: auto; height: 100%">
                <header class="modal-card-head">
                    <p class="modal-card-title">{{title}}</p>
                </header>
                <section class="modal-card-body">
                    <b-collapse class="card"
                                animation="slide"
                                v-for="category in getCategories"
                                :key="category.id">
                        <div slot="trigger"
                             slot-scope="props"
                             class="card-header"
                             role="button">
                            <p class="card-header-title">
                                {{ category.name }}
                            </p>
                            <a class="card-header-icon">
                                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                                </b-icon>
                            </a>
                        </div>
                        <div class="card-content">
                            <div class="content">
                                <div class="template" v-for="template_l in getChatTemplates(category.id)"
                                     v-bind:key="template_l.id"
                                     @click="selectTemplate(template_l)" >
                                    <b-icon v-if="isSelected(template_l)" icon="checkbox-marked-circle-outline"></b-icon>
                                    <b-icon v-else icon="checkbox-blank-circle-outline"></b-icon>
                                    <b>{{ template_l.name }}</b>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-rounded is-danger" type="button" @click="closeModal">Close</button>
                    <button class="button is-rounded is-success"> Save </button>
                    <div v-if="template.hasOwnProperty('name')">
                        <b>{{message}} :</b> {{ template.name }}
                    </div>
                </footer>
            </div>
        </form>
    </b-modal>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "SelectTemplateForm",
        props:{
            isEdit: Boolean,
            isActive: Boolean,
            template_id: Number,
        },
        data() {
            return {
                title: 'Select Chat Template',
                message: 'Select template',
                template: {},
            }
        },
        created() {
            if (this.template_id > 0){
                this.selectTemplate(this.getChatTemplate(this.template_id));
            } else {
                this.template = {};
            }
            //this.refresh();
        },
        computed: {
            ...mapGetters(['getChatTemplatesCategories', 'getChatTemplates', 'getChatTemplate']),
            getCategories: function () {
                const that = this;
                let categories = JSON.parse(JSON.stringify(that.getChatTemplatesCategories));
                return categories.sort((a, b) => a.name.localeCompare(b.name))
            },

        },
        methods: {
           refresh() {
                this.$store.dispatch('CHAT_TEMPLATES_CATEGORIES_GET');
            },
            closeModal() {
                this.$emit('close');
            },
            selectTemplate(payload) {
                this.template = JSON.parse(JSON.stringify(payload))
                this.message = 'Template Selected';
            },
            saveTemplate() {
                if(this.template.hasOwnProperty('id') && this){
                    this.$emit('save',   { template_id: this.template.id})
                    this.$emit('close');
                } else {
                    this.$buefy.dialog.alert('You should select a template before saving');
                }
            },
            isSelected(payload) {
                return this.template.id === payload.id;
            }
        }
    }
</script>

<style scoped>
    .template {
        border: 1px solid lightgray;
        border-radius: 5px;
        height: 40px;
        text-align: left;
        padding: 5px;
        margin: 5px;
    }

    .card-content {
        padding: 5px;
    }
</style>