<template>
    <section style="height: 100%">
        <div class="nutrition-list-container">
            <div class="nutrition-list dropzone" :role="role">
                <b-table :data="nutritions"
                         focusable
                         paginated
                         pagination-simple
                         :default-sort="['name', 'asc']"
                         per-page="13">
                    <template slot-scope="props">
                            <div class="nutrition-list-item draggable" :role="role" :id="props.row.id">
                                <div class="draghandler" v-if="hasPermission('write:nutrition_instructions')"><br></div>
                                <div class="nutrition-list-item-name">{{props.row.name}}</div>
                                <div v-if="hasPermission('write:nutrition_instructions')"
                                     class="nutrition-list-item-buttons">
                                    <b-button class="is-success is-rounded is-small"
                                              @click="editNutrition(props.row.id)">edit
                                    </b-button>
                                    <b-button class="is-danger is-rounded is-small"
                                              @click="confirmCustomDelete(props.row.id)">remove
                                    </b-button>
                                </div>
                            </div>
                    </template>
                </b-table>
            </div>
        </div>
        <div class="nutrition-list-buttons">
            <b-button v-if="hasPermission('write:nutrition_instructions')"
                      type="is-success is-rounded is-medium"
                      @click="createNutrition">
                Add
            </b-button>
            <b-button v-if="hasPermission('write:nutrition_instructions')&&role==2"
                      type="is-success is-rounded is-medium"
                      @click="createNutritionList">
                Add List
            </b-button>
        </div>
        <b-modal :active.sync="isCompModalActive"
                 has-modal-card
                 aria-role="dialog"
                 aria-modal>
            <add-nutrition-form
                    :pCloseModal="closeModal"
                    :id="id"
                    :isEdit="isEdit"
                    :role=role>
            </add-nutrition-form>
        </b-modal>
        <b-modal :active.sync="isListCompModalActive"
                 has-modal-card
                 aria-role="dialog"
                 aria-modal>
            <add-nutrition-list-form
                    :pCloseModal="closeListModal"
                    :id="id"
                    :isEdit="isEdit"
                    :role=role>
            </add-nutrition-list-form>
        </b-modal>
    </section>
</template>

<script>
    import {mapGetters} from 'vuex'
    import AddNutritionForm from "./AddNutritionForm";
    import AddNutritionListForm from "./AddNutritionListForm";

    export default {
        name: "NutritionInstructions",
        components: {
            AddNutritionForm,
            AddNutritionListForm
        },
        props: {
            role: Number,
            filter: String
        },
        data() {
            return {
                isCompModalActive: false,
                isListCompModalActive: false,
                isEdit: false,
                edit_type: 'nutrition',
                id: -1,
                columns: [
                    {
                        field: 'name',
                        label: 'Name',
                    }]

            }
        },
        computed: {
            ...mapGetters(['getSpecificNutritionInstructions', 'hasPermission']),
            nutritions: {
                get() {
                    let filter = this.filter.toUpperCase();
                    return this.getSpecificNutritionInstructions(this.role)
                        .filter(el => el.name.toUpperCase().indexOf(filter) > -1)
                    //.sort((a, b) => a.name.localeCompare(b.name))
                }
            }
        },
        methods: {
            closeModal: function () {
                this.isCompModalActive = false;
            },
            closeListModal: function () {
                this.isListCompModalActive = false;
            },
            createNutrition() {
                this.isEdit = false;
                this.isCompModalActive = true;
            },
            editNutrition(id) {
                this.isEdit = true;
                this.id = id;
                this.isCompModalActive = true;
            },
            createNutritionList() {
                this.isEdit = false;
                this.isListCompModalActive = true;
            },
            confirmCustomDelete(id) {
                this.$buefy.dialog.confirm({
                    title: 'Delete nutrition',
                    message: 'Are you sure you want to <b>delete</b> nutrition? This action cannot be undone.',
                    confirmText: 'Delete nutrition',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.remove(id)
                })
            },
            remove(id) {
                this.$store.dispatch('NUTRITION_INSTRUCTION_REMOVE', id)
            },
        }
    }
</script>

<style scoped>
    .nutrition-list-container {
        padding: 0 0 55px 0;
        margin: 0 0 -55px 0;
        height: 100%;
    }

    .nutrition-list {
        height: 100%;
        overflow-y: scroll;
        text-align: left;
        border: 1px solid lightgray;

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                         supported by Chrome, Opera and Firefox */
    }

    .nutrition-list-item {
        height: 45px;
        padding: 7px;
        margin: 2px;
        border: 1px solid lightgray;
    }

    .nutrition-list-item-name {
        font-weight: bold;
        float: left;
        padding-left: 10px;
    }

    .nutrition-list-item-buttons {
        float: right;
        padding-right: 5px;
    }

    .nutrition-list-buttons {
        height: 55px;
        padding: 10px;
    }

    .draghandler {
        float: left;
        height: 27px;
        width: 27px;
        background-image: url("../../assets/svg/pawprint.svg");
        background-position: center;
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
    }

    /* (Optional) Apply a "closed-hand" cursor during drag operation. */
    .draghandler:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }
</style>