<template>
    <div class="tile is-ancestor is-12" style="height: 100%">
        <div v-if="chat_id > 0||isWide" class="tile is-parent is-6" style="height: 100%" >
            <div v-if="chat_id > 0" class="tile is-child chat" >
                <div class="chat_user"><b v-if="client_name.length>2">{{ client_name }}<b-button @click="close" type="is-small is-rounded is-danger">Х</b-button></b></div>
                <client-chat v-if="chat_id > 0"
                             :clientId="user_id"
                             :room_id="chat_id"
                />
            </div>
        </div>
        <div class="tile is-parent is-vertical is-6">
            <div class="tile is-child" style="position: relative">
                <support-clients-table v-if="chat_id===0||isWide" v-on:selectchat="changeChat"
                />
            </div>
        </div>
    </div>
</template>

<script>

    import ClientChat from "../components/ClientPage/ClientChat";
    import SupportClientsTable from "../components/SupportClientsTable";

    export default {
        name: "SupportPage",
        components:{
            ClientChat,
            SupportClientsTable,
        },
        data() {
            return {
                user_id: '',
                chat_id: 0,
                client_name: '',
                isWide: true,
            }
        },
        created() {
            window.addEventListener('resize', this.resizeTable);
            this.resizeTable();
            this.user_id = '';
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.resizeTable);
        },
        methods: {
            resizeTable() {
                this.height = 'height: ' + Math.floor(window.innerHeight) + 'px;';
                this.isWide = window.innerWidth > 768;
            },
            changeChat(payload){
                this.user_id = payload.client;
                this.chat_id = payload.room;
                this.client_name = payload.name;
            },
            close() {
                this.user_id = '';
                this.chat_id = 0;
                this.client_name = '';
            }
        }

    }
</script>

<style scoped>
    .chat {
    }

    .chat_user {
        position: absolute;
        top:0;
        left: 155px;
        padding: 10px;
        font-weight: bolder;
        z-index: 99999;
    }

    @media (max-width: 768px) {
        .chat {
            height: 100%;
            padding: 0;
            margin: 0;
        }
    }

</style>