<template>
    <section>
        <div class="variant content">
            <b-field>
                <b-icon v-if="button.template_id>0" icon="check" type="is-success"/>
                <b-icon v-else icon="close" type="is-danger" />
                <b-input v-model="caption"  class="button_captioin"/>
                <b-button @click="selectTemplate()" class="button is-rounded is-success is-small">
                    <b-icon icon="pencil"></b-icon>
                </b-button>
                <b-button @click="removeButton" class="button is-rounded is-danger is-small">
                    <b-icon class="is-small is-danger" icon="close-circle"></b-icon>
                </b-button>
            </b-field>
        </div>
        <select-template-form v-if="isSelectTemplateActive"
                              :is-active="isSelectTemplateActive"
                              v-on:close="isSelectTemplateActive=false"
                              v-on:save="setSelectedTemplate"
                              :template_id="template_id"
        />
    </section>
</template>

<script>
    import SelectTemplateForm from "./SelectTemplateForm";

    export default {
        name: "EditButtonForm",
        components: {
            SelectTemplateForm,
        },
        props: {
            index: Number,
            data: {},
        },
        created() {
            this.button = JSON.parse(JSON.stringify(this.data))
        },
        data() {
            return {
                button: {},
                isSelectTemplateActive: false,
            }
        },
        computed: {
            caption: {
                get() {
                    if (this.button.hasOwnProperty('caption')) return this.button.caption
                    return ''
                },
                set(payload) {
                    this.button.caption = payload
                    this.saveButton()
                }
            },
            template_id: {
                get() {
                    return this.button.template_id
                },
                set(payload) {
                    this.button.template_id = payload
                    this.saveButton()
                }
            }

        },
        methods: {
            saveButton() {
                this.$emit('save', {index: this.index, button: this.button})
            },
            removeButton() {
                this.$emit('remove', {index: this.index})
            },
            selectTemplate() {
                this.isSelectTemplateActive = true;
            },
            setSelectedTemplate(payload) {
                this.template_id = payload.template_id
                this.isSelectTemplateActive = true;
            }

        }
    }
</script>

<style scoped>
    .button_captioin {
        width: 100%;
        border-radius: 5px;
        padding: 5px;
    }
</style>