<template>
    <div class="pagecontainer" v-drag-and-drop:options="dndoptions">
        <div class="pageheader">
            <b-button @click="addCategory" type="is-success is-rounded">add category</b-button>
        </div>
        <div class="category_container">
            <div v-for="category in getCategories"
                 v-bind:key="category.id"
                 class="category">
                <div class="category_header">
                    <b>{{category.name}}</b>
                    <div style="float: right">
                        <b-button @click="editCategory(category.id)"
                                  class="is-rounded is-success is-small">
                            <b-icon icon="pencil"></b-icon>
                        </b-button>
                    </div>
                </div>
                <div class="category_body dropzone" :category="category.id">
                    <div class="template draggable" v-for="template in getChatTemplates(category.id)"
                         v-bind:key="template.id" :template="template.id">
                        <div class="draghandler"><br></div>
                        <div style="float: right">
                            <b-button @click="editTemplate(category.id, template.id)"
                                      class="is-rounded is-success is-small">
                                <b-icon icon="pencil"></b-icon>
                            </b-button>
                            <b-button @click="confirmCustomDeleteTemplate(category.id, template.id)"
                                      class="is-rounded is-danger is-small">
                                <b-icon class="is-small is-danger" icon="close-circle"></b-icon>
                            </b-button>
                        </div>
                        <div class="template_name"><b> {{ template.name }}</b> &nbsp;</div>
                    </div>
                </div>
                <div class="category_footer">
                    <b-button @click="addTemplate(category.id)" type="is-success is-rounded"> add template</b-button>
                    <b-button @click="confirmCustomDelete(category.id)" type="is-danger is-rounded"> remove category
                    </b-button>
                </div>
            </div>
        </div>
        <b-modal :active.sync="isCompModalActive"
                 has-modal-card
                 aria-role="dialog"
                 aria-modal>
            <add-chat-templates-category-form
                    :pCloseModal="closeModal"
                    :pRefresh="refresh"
                    :fdata="formdata"
                    :isEdit="isEdit">
            </add-chat-templates-category-form>
        </b-modal>
        <b-modal :active.sync="isCompModalActiveTemplate"
                 has-modal-card
                 aria-role="dialog"
                 aria-modal>
            <add-chat-template-form
                    :pCloseModal="closeModalTemplate"
                    :pRefresh="refresh"
                    :category_id="category_id"
                    :fdata="formdata"
                    :isEdit="isEdit">
            </add-chat-template-form>
        </b-modal>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import AddChatTemplatesCategoryForm from "./AddChatTemplatesCategoryForm";
    import AddChatTemplateForm from "./AddChatTemplateForm";

    export default {
        name: "ChatTemplatesCategories",
        components: {
            AddChatTemplatesCategoryForm,
            AddChatTemplateForm,
        },
        data() {
            return {
                category_id: 0,
                isCompModalActive: false,
                isCompModalActiveTemplate: false,
                isEdit: false,
                formdata: {},
            }
        },
        created() {
            this.refresh();
            this.dndoptions = {
                dropzoneSelector: '.dropzone',
                draggableSelector: '.draggable',
                handlerSelector: '.draghandler',
                showDropzoneAreas: false,
                onDragend: function (event) {
                    if (event.droptarget != null) {
                        let fromTemplate = event.items[0].attributes['template'].value;
                        let toCategory = event.droptarget.attributes['category'].value;
                        this.moveTemplate(toCategory, fromTemplate);
                    }
                }
            };
        },

        computed: {
            ...mapGetters(['getChatTemplatesCategories', 'getChatTemplates', 'getChatTemplatesCategory']),
            getCategories: function () {
                const that = this;
                let categories = JSON.parse(JSON.stringify(that.getChatTemplatesCategories));
                return categories.sort((a, b) => a.name.localeCompare(b.name))
            },
        },
        methods: {
            refresh() {
                this.$store.dispatch('CHAT_TEMPLATES_CATEGORIES_GET');
            },
            addCategory() {
                this.isCompModalActive = true;
            },
            closeModal() {
                this.isCompModalActive = false;
            },
            addTemplate(id) {
                this.category_id = id;
                this.isEdit = false;
                this.formdata = {};
                this.isCompModalActiveTemplate = true;
            },
            closeModalTemplate() {
                this.isCompModalActiveTemplate = false;
            },
            confirmCustomDelete(id) {
                this.$buefy.dialog.confirm({
                    title: 'Delete chat templates category',
                    message: 'Are you sure you want to <b>delete</b> templates category? This action cannot be undone.',
                    confirmText: 'Delete category',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.removeCategory(id)
                })
            },
            async removeCategory(id) {
                await this.$store.dispatch('CHAT_TEMPLATES_CATEGORY_REMOVE', {'id': id});
                this.refresh();
            },
            confirmCustomDeleteTemplate(category_id, id) {
                this.$buefy.dialog.confirm({
                    title: 'Delete chat template',
                    message: 'Are you sure you want to <b>delete</b> template? This action cannot be undone.',
                    confirmText: 'Delete template',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.removeTemplate(category_id, id)
                })
            },
            async removeTemplate(category_id, id) {
                await this.$store.dispatch('CHAT_TEMPLATES_REMOVE', {category_id: category_id, 'id': id});
                this.refresh();
            },
            editCategory(category_id) {
                this.category_id = category_id;
                this.formdata = this.getChatTemplatesCategory(category_id);
                this.isEdit = true;
                this.isCompModalActive = true;
            },
            editTemplate(category_id, template_id) {
                this.category_id = category_id;
                this.formdata = this.getChatTemplates(category_id).find(el => el.id === template_id);
                this.isEdit = true;
                this.isCompModalActiveTemplate = true;
            },
            async moveTemplate(cat_id, id){
                await this.$store.dispatch('CHAT_TEMPLATES_MOVE', {
                    fromTemplate: id,
                    toCategory: cat_id
                });
                this.refresh();
            }
        }

    }
</script>

<style scoped>
    .pagecontainer {
        position: relative;
        height: 100vh;
    }

    .pageheader {
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
        height: 50px;
        padding: 5px;
    }

    .category_container {
        position: absolute;
        display: flex;
        flex-wrap: nowrap;
        left: 0;
        top: 50px;
        right: 0;
        bottom: 0;
        overflow-x: scroll;
        text-align: left;
    }

    .category {
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        display: inline-block;
        position: relative;
        width: 300px;
        height: 100%;
        border: 1px solid lightgray;
        border-radius: 5px;
        margin-left: 5px;
    }

    .category_header {
        position: absolute;
        top: 0;
        height: 50px;
        padding: 5px;
        width: 100%;
        border: 1px solid lightgray;
        background-color: lightgray;
        text-align: center;
    }

    .category_body {
        position: absolute;
        top: 50px;
        bottom: 50px;
        width: 100%;
        border: 1px solid lightgray;
        text-align: center;
        overflow-y: auto;

    }

    .category_footer {
        position: absolute;
        bottom: 0;
        height: 50px;
        width: 100%;
        border: 1px solid lightgray;
        background-color: lightgray;
        text-align: center;
        padding: 5px;
    }

    .template {
        border: 1px solid lightgray;
        border-radius: 5px;
        text-align: left;
        padding: 5px;
        margin: 5px;
    }

    .template_name {
        padding: 0 0 0 30px;
    }


    .draghandler {
        float: left;
        height: 27px;
        width: 27px;
        background-image: url("../../assets/svg/pawprint.svg");
        background-position: center;
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
    }

    /* (Optional) Apply a "closed-hand" cursor during drag operation. */
    .draghandler:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }
</style>