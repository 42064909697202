<template>
    <section>
        <b-button type="is-success is-rounded is-medium"
                  @click="addSupplementsItem">
            Add Supplements Item
        </b-button>
        <b-modal :active.sync="isViewModalActive"
                 has-modal-card
                 aria-role="dialog"
                 aria-modal>
            <view-supplements-item
                    :pCloseModal="closeViewModal"
                    :item="item"
            >
            </view-supplements-item>
        </b-modal>
        <b-modal :active.sync="isAddModalActive"
                 has-modal-card
                 aria-role="dialog"
                 aria-modal>
            <add-supplements-item
                    :pCloseModal="closeAddModal"
                    :isEdit="isEdit"
                    :item="item"
                    >
            </add-supplements-item>
        </b-modal>

        <b-table
            :data="supplementsItems"
            :paginated="true"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :default-sort-direction="defaultSortDirection"
            :sort-icon="sortIcon"
            :sort-icon-size="sortIconSize"
            default-sort="name"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
        >
            <template slot-scope="props">
                <b-table-column field="id" label="ID" width="70" sortable numeric>
                    {{ props.row.id }}
                </b-table-column>
                <b-table-column field="name" label="Name" sortable>
                    {{ props.row.name }}
                </b-table-column>
                <b-table-column field="active_ingredient" label="Active ingredient" sortable>
                    {{ props.row.active_ingredient }}
                </b-table-column>
                <b-table-column label="View" width="70px">
                    <b-button type="is-success is-rounded is-small" @click="viewSupplementsItem(props.row)">View</b-button>
                </b-table-column>
                <b-table-column label="Edit" width="70px">
                    <b-button type="is-success is-rounded is-small" @click="editSupplementsItem(props.row)">Edit</b-button>
                </b-table-column>
                <b-table-column label="Delete" width="100px">
                    <b-button type="is-danger is-rounded is-small" @click="confirmCustomDelete(props.row.id)">Delete</b-button>
                </b-table-column>
            </template>
        </b-table>
    </section>
</template>

<script>
    import {mapGetters} from 'vuex'

    import ViewSupplementsItem from "../components/SupplementsAdminPage/ViewSupplementsItem";
    import AddSupplementsItem from "../components/SupplementsAdminPage/AddSupplementsItem";
    import RespInfo from "../utils/respinfo";

    export default {
        name: "MedicineAdminPage",
        components: {
            AddSupplementsItem,
            ViewSupplementsItem
        },
        data() {
            return {
                data:[{'id':1},{'id':2}],
                currentPage: 1,
                perPage: 20,
                isPaginationSimple: true,
                paginationPosition: 'top',
                defaultSortDirection: 'asc',
                sortIcon: 'arrow-up',
                sortIconSize: 'is-small',
                item:{},
                isViewModalActive: false,
                isAddModalActive: false,
                isEdit: false,
            }
        },
        computed: {
            ...mapGetters(['getSupplementsItems', 'getSupplementsItemsStatus']),
            supplementsItems: {
                get() {
                    const supplementsItems = Object.values(this.getSupplementsItems);
                    return supplementsItems;
                }
            },
            isLoading: {
                get() {
                    if (this.getSupplementsItemsStatus == 'loading') return true;
                    return false;
                }
            }
        },
        created() {
            this.refresh();
        },
        methods: {
            async refresh() {
               await this.$store.dispatch('SUPPLEMENTS_ITEMS_LIST_GET')
            },
            viewSupplementsItem(item) {
                this.item = item
                this.isViewModalActive = true
            },
            closeViewModal() {
                this.isViewModalActive = false
            },
            addSupplementsItem() {
                this.isEdit = false
                this.item = {
                    name:'',
                    active_ingredient:'',
                    way_of_consumption:'',
                    comments:'',
                }
                this.isAddModalActive = true
            },
            editSupplementsItem(item) {
                this.isEdit = true
                this.item = item
                this.isAddModalActive = true
            },
            closeAddModal() {
                this.isAddModalActive = false
            },
            confirmCustomDelete(id) {
                this.$buefy.dialog.confirm({
                    title: 'Delete Supplements Item',
                    message: 'Are you sure you want to <b>delete</b> Supplements Item? This action cannot be undone.',
                    confirmText: 'Delete supplements item',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.removeSupplements(id)
                })
            },
            async removeSupplements(id) {
                let resp = await this.$store.dispatch('SUPPLEMENTS_ITEMS_DELETE', id);
                this.$buefy.toast.open(RespInfo(resp));
            },
        }
    }
</script>

<style scoped>
</style>